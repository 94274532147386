import loadable from '@loadable/component'
import { Route } from 'react-router-dom'
import RouteGuard from './components/RouteGuard'

// ib site
// import Ideabubble from "./pages/ideabubble/Ideabubble";
// import OurWork from "./pages/ideabubble/OurWork";
// import ContactUs from "./pages/ideabubble/ContactUs";
// import PrivacyPolicy from "./pages/ideabubble/Privacy";
// import AboutUs from "./pages/ideabubble/AboutUs";
// import MeetTeam from "./pages/ideabubble/MeetTeam";
// import OurPlatform from "./pages/ideabubble/OurPlatform";
// import IBNews from "./pages/ideabubble/News";
// import IBNewsArticle from "./pages/ideabubble/NewsArticle";

//consultar IB site
// import ConsultarLayout from "./pages/ideabubbleConsultar/ConsultarLayout";
// import Homepage from "./pages/ideabubbleConsultar/main-component/HomePage";
// import OurStoryPage from "./pages/ideabubbleConsultar/main-component/OurStoryPage";
// import PricingPage from "./pages/ideabubbleConsultar/main-component/PricingPage";
// import PricingUKPage from "./pages/ideabubbleConsultar/main-component/PricingUKPage";
// import TestimonialPage from "./pages/ideabubbleConsultar/main-component/TestimonialPage";
// import NewsPage from "./pages/ideabubbleConsultar/main-component/NewsPage";
// import NewsDetailedPage from "./pages/ideabubbleConsultar/main-component/NewsDetailedPage";
// import PrivacyPage from "./pages/ideabubbleConsultar/main-component/PrivacyPage";
// import GetDemo from "./pages/ideabubbleConsultar/main-component/GetDemo";
// import CustomersPage from "./pages/ideabubbleConsultar/main-component/CustomersPage";
// import OurClientsPage from "./pages/ideabubbleConsultar/main-component/OurClientsPage";
// import FeaturesOverviewPage from "./pages/ideabubbleConsultar/main-component/FeaturesOverviewPage";
// import CustomerPortalsPage from "./pages/ideabubbleConsultar/main-component/CustomerPortalsPage";
// import SitemapPage from "./pages/ideabubbleConsultar/main-component/SitemapPage";
// import UseCookiesPage from "./pages/ideabubbleConsultar/main-component/UseCookiesPage";
// import ErrorPage from "./pages/ideabubbleConsultar/main-component/ErrorPage";
// import ContactPage from "./pages/ideabubbleConsultar/main-component/ContactPage";
// import LoginPage from "./pages/ideabubbleConsultar/main-component/LoginPage";
// import SignUpPage from "./pages/ideabubbleConsultar/main-component/SignUpPage";
// import ConsultForgotPassword from "./pages/ideabubbleConsultar/main-component/ForgotPassword";
// import CRMPage from "./pages/ideabubbleConsultar/main-component/CRMPage";
// import BookingsPage from "./pages/ideabubbleConsultar/main-component/BookingsPage";
// import ELearningPage from "./pages/ideabubbleConsultar/main-component/ELearningPage";
// import SoftwareLicenseAgreementPage from "./pages/ideabubbleConsultar/main-component/SoftwareLicenseAgreementPage";
// import ThankYouPage from "./pages/ideabubbleConsultar/main-component/ThankYouPage";

// cc old site
// import CourseCo from "./pages/courseco/CourseCo";
// import PricingCC from "./pages/courseco/Pricing";
// import OurClientsCC from "./pages/courseco/OurClients";
// import ContactUsCC from "./pages/courseco/ContactUs";
// import GetDemoCC from "./pages/courseco/GetDemo";
// import OurStoryCC from "./pages/courseco/OurStory";
// import PlatformCC from "./pages/courseco/Platform";
// import NewsCC from "./pages/courseco/News";
// import NewsArticleCC from "./pages/courseco/NewsArticle";
// import FeaturesCC from "./pages/courseco/features/Features";
// import CustomerPortalsCC from "./pages/courseco/features/CustomerPortals";
// import CRMCC from "./pages/courseco/features/CRM";
// import BookingsCC from "./pages/courseco/features/Bookings";
// import ELearningCC from "./pages/courseco/features/ELearning";

// custom client website
const Template = loadable(() => import('./pages/ccClient/Template'))
const CourseList = loadable(() => import('./pages/ccClient/CourseList'))
const CourseDetail = loadable(() => import('./pages/ccClient/CourseDetail'))
const Checkout = loadable(() => import('./pages/ccClient/Checkout'))
const ThankYou = loadable(() => import('./pages/ccClient/ThankYou'))
const News = loadable(() => import('./pages/ccClient/News'))
const NewsPost = loadable(() => import('./pages/ccClient/NewsPost'))
const Login = loadable(() => import('./pages/ccClient/auth/Login'))
const InitLogin = loadable(() => import('./pages/ccClient/auth/InitLogin'))
const ConfirmLogin = loadable(() =>
  import('./pages/ccClient/auth/ConfirmLogin')
)
const PasswordReset = loadable(() =>
  import('./pages/ccClient/auth/PasswordReset')
)
const ForgotPassword = loadable(() =>
  import('./pages/ccClient/auth/ForgotPassword')
)
const AdminHome = loadable(() => import('./pages/ccAdmin/AdminHome'))
const AdminLayout = loadable(() => import('./pages/ccAdmin/AdminLayout'))
const AdminDashboard = loadable(() => import('./pages/ccAdmin/AdminDashboard'))
const Settings = loadable(() => import('./pages/ccAdmin/Settings'))
const AdminNews = loadable(() => import('./pages/ccAdmin/News/News'))
const WebsiteStyleGuide = loadable(() =>
  import('./pages/ccAdmin/WebsiteStyleGuide')
)

//consultar cc site
const ConsultarLayout = loadable(() =>
  import('./pages/coursecoConsultar/ConsultarLayout')
)
const Homepage = loadable(() =>
  import('./pages/coursecoConsultar/main-component/HomePage')
)
const OurStoryPage = loadable(() =>
  import('./pages/coursecoConsultar/main-component/OurStoryPage')
)
const PricingPage = loadable(() =>
  import('./pages/coursecoConsultar/main-component/PricingPage')
)
const PricingUKPage = loadable(() =>
  import('./pages/coursecoConsultar/main-component/PricingUKPage')
)
const TestimonialPage = loadable(() =>
  import('./pages/coursecoConsultar/main-component/TestimonialPage')
)
const NewsPage = loadable(() =>
  import('./pages/coursecoConsultar/main-component/NewsPage')
)
const NewsDetailedPage = loadable(() =>
  import('./pages/coursecoConsultar/main-component/NewsDetailedPage')
)
const PrivacyPage = loadable(() =>
  import('./pages/coursecoConsultar/main-component/PrivacyPage')
)
const GetDemo = loadable(() =>
  import('./pages/coursecoConsultar/main-component/GetDemo')
)
const CustomersPage = loadable(() =>
  import('./pages/coursecoConsultar/main-component/CustomersPage')
)
const OurClientsPage = loadable(() =>
  import('./pages/coursecoConsultar/main-component/OurClientsPage')
)
const FeaturesOverviewPage = loadable(() =>
  import('./pages/coursecoConsultar/main-component/FeaturesOverviewPage')
)
const CustomerPortalsPage = loadable(() =>
  import('./pages/coursecoConsultar/main-component/CustomerPortalsPage')
)
const SitemapPage = loadable(() =>
  import('./pages/coursecoConsultar/main-component/SitemapPage')
)
const UseCookiesPage = loadable(() =>
  import('./pages/coursecoConsultar/main-component/UseCookiesPage')
)
const ErrorPage = loadable(() =>
  import('./pages/coursecoConsultar/main-component/ErrorPage')
)
const ContactPage = loadable(() =>
  import('./pages/coursecoConsultar/main-component/ContactPage')
)
const LoginPage = loadable(() =>
  import('./pages/coursecoConsultar/main-component/LoginPage')
)
const SignUpPage = loadable(() =>
  import('./pages/coursecoConsultar/main-component/SignUpPage')
)
const ConsultForgotPassword = loadable(() =>
  import('./pages/coursecoConsultar/main-component/ForgotPassword')
)
const CRMPage = loadable(() =>
  import('./pages/coursecoConsultar/main-component/CRMPage')
)
const BookingsPage = loadable(() =>
  import('./pages/coursecoConsultar/main-component/BookingsPage')
)
const ELearningPage = loadable(() =>
  import('./pages/coursecoConsultar/main-component/ELearningPage')
)
const SoftwareLicenseAgreementPage = loadable(() =>
  import(
    './pages/coursecoConsultar/main-component/SoftwareLicenseAgreementPage'
  )
)
const ThankYouPage = loadable(() =>
  import('./pages/coursecoConsultar/main-component/ThankYouPage')
)

const ConsultarLayoutIB = loadable(() =>
  import('./pages/ideabubbleConsultar/ConsultarLayout')
)
const HomepageIB = loadable(() =>
  import('./pages/ideabubbleConsultar/main-component/HomePage')
)
const Homepage2IB = loadable(() =>
  import('./pages/ideabubbleConsultar/main-component/HomePage2')
)
const Homepage3IB = loadable(() =>
  import('./pages/ideabubbleConsultar/main-component/HomePage3')
)
const AboutPageIB = loadable(() =>
  import('./pages/ideabubbleConsultar/main-component/AboutPage')
)
const ServicePageIB = loadable(() =>
  import('./pages/ideabubbleConsultar/main-component/ServicePage')
)
const ServicePageS2IB = loadable(() =>
  import('./pages/ideabubbleConsultar/main-component/ServicePageS2')
)
const ServiceSinglePageIB = loadable(() =>
  import('./pages/ideabubbleConsultar/main-component/ServiceSinglePage')
)
const ProjectPageIB = loadable(() =>
  import('./pages/ideabubbleConsultar/main-component/ProjectPage')
)
const ProjectPageS2IB = loadable(() =>
  import('./pages/ideabubbleConsultar/main-component/ProjectPageS2')
)
const ProjectSinglePageIB = loadable(() =>
  import('./pages/ideabubbleConsultar/main-component/ProjectSinglePage')
)
const PricingPageIB = loadable(() =>
  import('./pages/ideabubbleConsultar/main-component/PricingPage')
)
const TestimonialPageIB = loadable(() =>
  import('./pages/ideabubbleConsultar/main-component/TestimonialPage')
)
const BlogPageIB = loadable(() =>
  import('./pages/ideabubbleConsultar/main-component/BlogPage')
)
const BlogPageLeftIB = loadable(() =>
  import('./pages/ideabubbleConsultar/main-component/BlogPageLeft')
)
const BlogPageFullwidthIB = loadable(() =>
  import('./pages/ideabubbleConsultar/main-component/BlogPageFullwidth')
)
const BlogDetailsIB = loadable(() =>
  import('./pages/ideabubbleConsultar/main-component/BlogDetails')
)
const BlogDetailsLeftSiideIB = loadable(() =>
  import('./pages/ideabubbleConsultar/main-component/BlogDetailsLeftSiide')
)
const BlogDetailsFullIB = loadable(() =>
  import('./pages/ideabubbleConsultar/main-component/BlogDetailsFull')
)
const ErrorPageIB = loadable(() =>
  import('./pages/ideabubbleConsultar/main-component/ErrorPage')
)
const ContactPageIB = loadable(() =>
  import('./pages/ideabubbleConsultar/main-component/ContactPage')
)
const LoginPageIB = loadable(() =>
  import('./pages/ideabubbleConsultar/main-component/LoginPage')
)
const SignUpPageIB = loadable(() =>
  import('./pages/ideabubbleConsultar/main-component/SignUpPage')
)
const ForgotPasswordIB = loadable(() =>
  import('./pages/ideabubbleConsultar/main-component/ForgotPassword')
)
const courseCoRoutes = (
  <Route
    path='/'
    element={<ConsultarLayout />}
  >
    <Route
      path='/'
      element={<Homepage />}
    />
    <Route
      path='/home'
      element={<Homepage />}
    />
    <Route
      path='/our-story'
      element={<OurStoryPage />}
    />
    <Route
      path='/pricing-plans'
      element={<PricingPage />}
    />
    <Route
      path='/pricing-uk'
      element={<PricingUKPage />}
    />
    <Route
      path='/testimonials'
      element={<TestimonialPage />}
    />
    <Route
      path='/contact-us'
      element={<ContactPage />}
    />
    <Route
      path='/news'
      element={<NewsPage />}
    />
    <Route
      path='/news/:id'
      element={<NewsDetailedPage />}
    />
    <Route
      path='/privacy-policy'
      element={<PrivacyPage />}
    />
    <Route
      path='/get-a-demo'
      element={<GetDemo />}
    />
    <Route
      path='/customers'
      element={<CustomersPage />}
    />
    <Route
      path='/our-clients'
      element={<OurClientsPage />}
    />
    <Route
      path='/features-overview'
      element={<FeaturesOverviewPage />}
    />
    <Route
      path='/customer-portals'
      element={<CustomerPortalsPage />}
    />
    <Route
      path='/crm'
      element={<CRMPage />}
    />
    <Route
      path='/course-management'
      element={<BookingsPage />}
    />
    <Route
      path='/e-learning'
      element={<ELearningPage />}
    />
    <Route
      path='/sitemap.html'
      element={<SitemapPage />}
    />
    <Route
      path='/our-use-of-cookies.html'
      element={<UseCookiesPage />}
    />
    <Route
      path='/software-license-agreement'
      element={<SoftwareLicenseAgreementPage />}
    />
    <Route
      path='/thank-you'
      element={<ThankYouPage />}
    />

    {/* <Route
                path="/blog-left-sidebar"
                element={<BlogPageLeft />}
              />
              <Route
                path="/blog-fullwidth"
                element={<BlogPageFullwidth />}
              /> */}
    {/* <Route path="/home2" element={<Homepage2 />} /> */}
    {/* <Route path="/home3" element={<Homepage3 />} /> */}
    {/* <Route path="/service" element={<ServicePage />} /> */}
    {/* <Route path="/service-s2" element={<ServicePageS2 />} /> */}
    {/* <Route
                path="/service-single"
                element={<ServiceSinglePage />}
              />
              <Route path="/project" element={<ProjectPage />} />
              <Route path="/project-s2" element={<ProjectPageS2 />} /> */}
    {/* <Route
                path="/project-single"
                element={<ProjectSinglePage />}
              /> */}
    {/* <Route path="/blog-single" element={<BlogDetails />} />
              <Route
                path="/blog-single-left-sidebar"
                element={<BlogDetailsLeftSiide />}
              />
              <Route
                path="/blog-single-fullwidth"
                element={<BlogDetailsFull />}
              /> */}
    <Route
      path='/login'
      element={<LoginPage />}
    />
    <Route
      path='/register'
      element={<SignUpPage />}
    />
    <Route
      path='/forgot-password'
      element={<ConsultForgotPassword />}
    />
    <Route
      path='/404'
      element={<ErrorPage />}
    />
  </Route>
)

//  {/* cc OLD marketing site */}
//         {/* <Route path="/courseco" element={<CourseCo />} />
//           <Route path="/courseco/pricing-plans" element={<PricingCC />} />
//           <Route path="/courseco/our-clients" element={<OurClientsCC />} />
//           <Route path="/courseco/contact-us" element={<ContactUsCC />} />
//           <Route path="/courseco/get-a-demo" element={<GetDemoCC />} />
//           <Route path="/courseco/our-story" element={<OurStoryCC />} />
//           <Route path="/courseco/platform" element={<PlatformCC />} />
//           <Route path="/courseco/news" element={<NewsCC />} />
//           <Route path="/courseco/news/:id" element={<NewsArticleCC />} />
//           <Route path="/courseco/features-overview" element={<FeaturesCC />} />
//           <Route
//             path="/courseco/customer-portals"
//             element={<CustomerPortalsCC />}
//           />
//           <Route path="/courseco/crm" element={<CRMCC />} />
//           <Route path="/courseco/course-management" element={<BookingsCC />} />
//           <Route path="/courseco/e-learning" element={<ELearningCC />} /> */}
const ideabubbleRoutes = (
  <Route
    path='/'
    element={<ConsultarLayoutIB />}
  >
    <Route
      path='/'
      element={<HomepageIB />}
    />
    <Route
      path='/home'
      element={<HomepageIB />}
    />
    <Route
      path='/home2'
      element={<Homepage2IB />}
    />
    <Route
      path='/home3'
      element={<Homepage3IB />}
    />
    <Route
      path='/about'
      element={<AboutPageIB />}
    />
    <Route
      path='/service'
      element={<ServicePageIB />}
    />
    <Route
      path='/service-s2'
      element={<ServicePageS2IB />}
    />
    <Route
      path='/service-single'
      element={<ServiceSinglePageIB />}
    />
    <Route
      path='/project'
      element={<ProjectPageIB />}
    />
    <Route
      path='/project-s2'
      element={<ProjectPageS2IB />}
    />
    <Route
      path='/project-single'
      element={<ProjectSinglePageIB />}
    />
    <Route
      path='/pricing'
      element={<PricingPageIB />}
    />
    <Route
      path='/testimonial'
      element={<TestimonialPageIB />}
    />
    <Route
      path='/404'
      element={<ErrorPageIB />}
    />
    <Route
      path='/contact'
      element={<ContactPageIB />}
    />
    <Route
      path='/blog'
      element={<BlogPageIB />}
    />
    <Route
      path='/blog-left-sidebar'
      element={<BlogPageLeftIB />}
    />
    <Route
      path='/blog-fullwidth'
      element={<BlogPageFullwidthIB />}
    />
    <Route
      path='/blog-single'
      element={<BlogDetailsIB />}
    />
    <Route
      path='/blog-single-left-sidebar'
      element={<BlogDetailsLeftSiideIB />}
    />
    <Route
      path='/blog-single-fullwidth'
      element={<BlogDetailsFullIB />}
    />
    <Route
      path='/login'
      element={<LoginPageIB />}
    />
    <Route
      path='/register'
      element={<SignUpPageIB />}
    />
    <Route
      path='/forgot-password'
      element={<ForgotPasswordIB />}
    />
  </Route>
)

const demoRoutes = (
  <>
    <Route
      path='/'
      element={<Template />}
    />
    <Route
      path='/courses'
      element={<CourseList />}
    />
    <Route
      path='/courses/:id'
      element={<CourseDetail />}
    />
    <Route
      path='/checkout'
      element={<Checkout />}
    />
    <Route
      path='/thank-you'
      element={<ThankYou />}
    />
    <Route
      path='/news'
      element={<News />}
    />
    <Route
      path='/news/:id'
      element={<NewsPost />}
    />
    <Route
      path='/auth/login'
      element={
        <RouteGuard guest={true}>
          <ConfirmLogin />
        </RouteGuard>
      }
    />
    <Route
      path='/init-login'
      element={
        <RouteGuard guest={true}>
          <InitLogin />
        </RouteGuard>
      }
    />
    <Route
      path='/login'
      element={<Login />}
    />
    <Route
      path='/forgot-password'
      element={<ForgotPassword />}
    />
    <Route
      path='/reset-password'
      element={<PasswordReset />}
    />
    <Route
      path='/admin/reset-password/:token'
      element={
        <RouteGuard guest={true}>
          <PasswordReset />
        </RouteGuard>
      }
    />
    <Route
      path='/'
      element={<AdminLayout />}
    >
      <Route
        path='/admin'
        element={
          <RouteGuard auth={false}>
            <AdminHome />
          </RouteGuard>
        }
      />
      <Route
        path='/admin/dashboards'
        element={
          <RouteGuard auth={false}>
            <AdminDashboard />
          </RouteGuard>
        }
      />

      <Route
        path='/admin/settings'
        element={
          <RouteGuard guest={true}>
            <Settings />
          </RouteGuard>
        }
      />
      <Route
        path='/admin/styleguide/website'
        element={
          <RouteGuard>
            <WebsiteStyleGuide />
          </RouteGuard>
        }
      />
    </Route>
    <Route
      path='/admin/news'
      element={
        <RouteGuard auth={false}>
          <AdminNews />
        </RouteGuard>
      }
    />
  </>
)
export { courseCoRoutes, ideabubbleRoutes, demoRoutes }
