import React, { useState, useEffect } from "react";

const ThemeSwitcher = () => {
  const [theme, setTheme] = useState("theme-default");
  const [toggleSwitcher, setToggleSwitcher] = useState(false);

  useEffect(() => {
    const currentTheme = JSON.parse(localStorage.getItem("current-theme"));
    if (currentTheme) {
      setTheme(currentTheme);
    }
  }, []);

  const handleTheme = (theme) => {
    setTheme(theme);
    localStorage.setItem("current-theme", JSON.stringify(theme));
  };

  useEffect(() => {
    document.body.classList.add(theme);
    return () => {
      document.body.classList.remove(theme);
    };
  }, [theme]);

  return (
    <div className="z-40 hidden md:flex flex-col items-center py-3 px-1 gap-2 fixed left-0 top-24 rounded-r-lg bg-white shadow-lg shadow-secondary-500">
      <button
        onClick={() => setToggleSwitcher(!toggleSwitcher)}
        className="text-secondary-500"
      >
        {toggleSwitcher ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        ) : (
          <svg
            viewBox="0 0 39 35"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6"
          >
            <path d="M37.7213 3.46789H29.4098C28.8344 1.47706 27.0443 0 24.8705 0C22.6967 0 20.9066 1.47706 20.3311 3.46789H1.27869C0.57541 3.46789 0 4.04587 0 4.75229C0 5.45872 0.57541 6.0367 1.27869 6.0367H20.3311C20.9066 8.02752 22.6967 9.50459 24.8705 9.50459C27.0443 9.50459 28.8344 8.02752 29.4098 6.0367H37.7213C38.4246 6.0367 39 5.45872 39 4.75229C39 4.04587 38.4246 3.46789 37.7213 3.46789ZM24.8705 6.93578C23.6557 6.93578 22.6967 5.97248 22.6967 4.75229C22.6967 3.53211 23.6557 2.56881 24.8705 2.56881C26.0852 2.56881 27.0443 3.53211 27.0443 4.75229C27.0443 5.97248 26.0213 6.93578 24.8705 6.93578Z"></path>
            <path d="M1.27869 18.7523H9.59016C10.1656 20.7431 11.9557 22.2202 14.1295 22.2202C16.3033 22.2202 18.0934 20.7431 18.6689 18.7523H37.7213C38.4246 18.7523 39 18.1743 39 17.4679C39 16.7614 38.4246 16.1835 37.7213 16.1835H18.7328C18.1574 14.1926 16.3672 12.7156 14.1934 12.7156C12.0197 12.7156 10.2295 14.1926 9.6541 16.1835H1.27869C0.57541 16.1835 0 16.7614 0 17.4679C0 18.1743 0.57541 18.7523 1.27869 18.7523ZM14.1934 15.2844C15.4082 15.2844 16.3672 16.2477 16.3672 17.4679C16.3672 18.6881 15.4082 19.6514 14.1934 19.6514C12.9787 19.6514 12.0197 18.6881 12.0197 17.4679C12.0197 16.2477 12.9787 15.2844 14.1934 15.2844Z"></path>
            <path d="M37.7213 28.9632H29.4098C28.8344 26.9724 27.0443 25.4954 24.8705 25.4954C22.6967 25.4954 20.9066 26.9724 20.3311 28.9632H1.27869C0.57541 28.9632 0 29.5412 0 30.2477C0 30.9541 0.57541 31.5321 1.27869 31.5321H20.3311C20.9066 33.5229 22.6967 34.9999 24.8705 34.9999C27.0443 34.9999 28.8344 33.5229 29.4098 31.5321H37.7213C38.4246 31.5321 39 30.9541 39 30.2477C39 29.5412 38.4246 28.9632 37.7213 28.9632ZM24.8705 32.4311C23.6557 32.4311 22.6967 31.4678 22.6967 30.2477C22.6967 29.0275 23.6557 28.0642 24.8705 28.0642C26.0852 28.0642 27.0443 29.0275 27.0443 30.2477C27.0443 31.4678 26.0213 32.4311 24.8705 32.4311Z"></path>
          </svg>
        )}
      </button>
      {toggleSwitcher && (
        <>
          <div className="flex flex-col items-center">
            <p>CO:</p>
            <button
              className="rounded-full border border-white w-10 h-10 relative overflow-hidden"
              onClick={() => handleTheme("theme-default")}
              style={{ backgroundColor: "#517cab" }}
            >
              <span
                className="top-0 right-0 mx-auto absolute w-1/2 h-[120%] rounded-[50%]"
                style={{
                  backgroundColor: "#96c0d0",
                }}
              />
            </button>
          </div>
          <div className="flex flex-col items-center">
            <p>Irish:</p>
            <button
              className="rounded-full border border-white w-10 h-10 relative overflow-hidden"
              onClick={() => handleTheme("irish-times")}
              style={{ backgroundColor: "#1e3b49" }}
            >
              <span
                className="top-0 right-0 mx-auto absolute w-1/2 h-[120%] rounded-[50%]"
                style={{
                  backgroundColor: "#19a29e",
                }}
              />
            </button>
          </div>
          <div className="flex flex-col items-center">
            <p>Agility:</p>
            <button
              className="rounded-full border border-white w-10 h-10 relative overflow-hidden"
              onClick={() => handleTheme("agility")}
              style={{ backgroundColor: "#1c2b39" }}
            >
              <span
                className="top-0 right-0 mx-auto absolute w-1/2 h-[120%] rounded-[50%]"
                style={{
                  backgroundColor: "#f26924",
                }}
              />
            </button>
          </div>

          <div className="flex flex-col items-center mb-2">
            <p>Safe:</p>
            <button
              className="rounded-full border border-white w-10 h-10 relative overflow-hidden"
              onClick={() => handleTheme("safesol")}
              style={{ backgroundColor: "#212121" }}
            >
              <span
                className="top-0 right-0 mx-auto absolute w-1/2 h-[120%] rounded-[50%]"
                style={{
                  backgroundColor: "#e1332b",
                }}
              />
            </button>
          </div>

          <button
            className="rounded-full border border-white bg-secondary-500 w-10 h-10 relative overflow-hidden"
            onClick={() => handleTheme("theme-orange")}
            style={{ backgroundColor: "#850e35" }}
          >
            <span
              className="top-0 right-0 mx-auto absolute bg-primary-500"
              style={{
                borderRadius: "50%",
                height: "120%",
                width: "50%",
                backgroundColor: "#ee6983",
              }}
            />
          </button>
          <button
            className="rounded-full border border-white bg-secondary-500 w-10 h-10 relative overflow-hidden"
            onClick={() => handleTheme("theme-blue")}
            style={{ backgroundColor: "#277BC0" }}
          >
            <span
              className="top-0 right-0 mx-auto absolute bg-primary-500"
              style={{
                borderRadius: "50%",
                height: "120%",
                width: "50%",
                backgroundColor: "#FFB200",
              }}
            />
          </button>
          <button
            className="rounded-full border border-white bg-secondary-500 w-10 h-10 relative overflow-hidden"
            onClick={() => handleTheme("theme-pink")}
            style={{ backgroundColor: "#9194ff" }}
          >
            <span
              className="top-0 right-0 mx-auto absolute bg-primary-500"
              style={{
                borderRadius: "50%",
                height: "120%",
                width: "50%",
                backgroundColor: "#fc7096",
              }}
            />
          </button>
          <button
            className="rounded-full border border-white bg-secondary-500 w-10 h-10 relative overflow-hidden"
            onClick={() => handleTheme("theme-yellow")}
            style={{ backgroundColor: "#541690" }}
          >
            <span
              className="top-0 right-0 mx-auto absolute bg-primary-500"
              style={{
                borderRadius: "50%",
                height: "120%",
                width: "50%",
                backgroundColor: "#FF4949",
              }}
            />
          </button>
          <button
            className="rounded-full border border-white bg-secondary-500 w-10 h-10 relative overflow-hidden"
            onClick={() => handleTheme("theme-brown")}
            style={{ backgroundColor: "#411530" }}
          >
            <span
              className="top-0 right-0 mx-auto absolute bg-primary-500"
              style={{
                borderRadius: "50%",
                height: "120%",
                width: "50%",
                backgroundColor: "#D1512D",
              }}
            />
          </button>
        </>
      )}
    </div>
  );
};

export default ThemeSwitcher;
