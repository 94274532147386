import { createSlice } from "@reduxjs/toolkit";

export const themeSlice = createSlice({
  name: "theme",
  initialState: {
    newTheme: "theme-default",
  },
  reducers: {
    update: (state, action) => {
      return { ...state, newTheme: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const { update } = themeSlice.actions;

export default themeSlice.reducer;
