import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";

import { selectLoggedIn, selectInit, logout, login } from "../store/authSlice";
import api from "../api/index.js";

const RouteGuard = ({ children, auth, guest }) => {
  const dispatch = useDispatch();
  let loggedIn = useSelector(selectLoggedIn);
  let init = useSelector(selectInit);

  if (!init) {
    // Check if there is a token stored inside localStorage
    if (localStorage.getItem("token")) {
      api
        .get("user/info")
        .then((response) => {
          dispatch(
            login({
              user: response.data["authenticated-user"],
              token: localStorage.getItem("token"),
            })
          );
        })
        .catch(() => {
          dispatch(logout());
        });
    } else {
      dispatch(logout());
    }
    return "";
  } else {
    if (auth) {
      return loggedIn ? children : <Navigate to="/init-login" />;
    } else if (guest) {
      return !loggedIn ? children : <Navigate to="/admin" />;
    } else {
      return children;
    }
  }
};

export default RouteGuard;
