import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    init: false,
    loggedIn: false,
    user: null,
    token: null,
    permissions: [],
    roles: [],
  },
  reducers: {
    login: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.permissions = action.payload.permissions;
      state.roles = action.payload.roles;
      state.loggedIn = true;
      state.init = true;
    },
    logout: (state) => {
      state.user = null;
      state.loggedIn = false;
      state.init = true;
      localStorage.removeItem("token");
    },
    setUser: (state, action) => {
      state.user = action.payload;
      state.init = true;
    },
  },
});

export const selectToken = (state) => state.auth.token;
export const selectLoggedIn = (state) => state.auth.loggedIn;
export const selectUser = (state) => state.auth.user;
export const selectInit = (state) => state.auth.init;

export const { login, logout, setUser } = authSlice.actions;

export default authSlice.reducer;
