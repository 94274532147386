// ConfigContext.js

import { createContext, useContext, useState, useEffect } from 'react';

const ConfigContext = createContext();

export const ApiConfigProvider = ({ children }) => {
    const [apiConfig, setApiConfig] = useState(null);
    useEffect(() => {
      const fetchApiConfig = async () => {
        var apiURl = window.location.protocol + "//api." + window.location.hostname + "/3.0/";
        // apiURl = "https://api.courseco.test.courseco.co/3.0/";
        
        try {
          const response = await fetch(apiURl + 'settings/list');
          const resp = await response.json();
          setApiConfig(resp.data.settings);
        } catch (error) {
          console.error('Error fetching API config:', error);
        }
      };
  
      fetchApiConfig();
    }, []);
  return (
    <ConfigContext.Provider value={apiConfig}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useApiConfig = () => {
  return useContext(ConfigContext);
};
