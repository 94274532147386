import { createSlice } from "@reduxjs/toolkit";

export const newsSlice = createSlice({
  name: "news",
  initialState: {
    articles: [],
  },
  reducers: {
    add: (state, action) => {
      state.articles.push(action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { add } = newsSlice.actions;

export default newsSlice.reducer;
