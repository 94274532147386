import React from "react";

import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Provider } from "react-redux";
import loadable from "@loadable/component";

import store from "./store";
import ScrollToTop from "./utils/scrollToTop";
import ThemeSwitcher from "./utils/themeSwitcher";
import { courseCoRoutes, ideabubbleRoutes, demoRoutes } from "./routes";

import "./App.scss";

const ErrorPage = loadable(() =>
  import("./pages/coursecoConsultar/main-component/ErrorPage")
);
const Page404 = loadable(() => import("./pages/ccClient/Page404"));

function App() {
  const website = window.websiteid;
  const isCourseCo = website === "courseco";
  const isIdeabubble = website === "ideabubble";
  const isDemo = website === "courseco-demo-v3";

  const showThemeSwitcher = isDemo || (!isCourseCo && !isIdeabubble && !isDemo);
  const routes = isCourseCo
    ? courseCoRoutes
    : isIdeabubble
    ? ideabubbleRoutes
    : demoRoutes;

  return (
    <Provider store={store}>
      <BrowserRouter>
        {/* update page top offset to 0, when using NavLink or switching route */}
        <ScrollToTop />
        {showThemeSwitcher && <ThemeSwitcher />}
        <Routes>
          {routes}

          <Route
            path="/404"
            element={isCourseCo ? <ErrorPage /> : <Page404 />}
          />
          <Route
            path="*"
            element={
              <Navigate
                to="/404"
                // to={nameUrl.includes("/consultar") ? "/404" : "/404"}
                replace
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
