import axios from "axios";

const myAxios = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

// Add request interceptor for setting auth token
myAxios.interceptors.request.use((config) => {
  let token = localStorage.getItem("token");
  config.headers.Authorization = token ? "Bearer " + token : null;

  return config;
});

export default myAxios;
